export const CategoriesDropdown = [
  {
    id: "1",
    title: "Outdoor Advertising",
    link: "/outdoor-advertising-agency/",
  },
  {
    id: "2",
    title: "Metro Advertising",
    link: "/metro-advertising-agency/",
  },
  {
    id: "3",

    title: "Airport Branding",
    link: "/airport-branding-advertising-agency/",
  },
  {
    id: "4",
    title: "Mall Branding",
    link: "/mall-branding-advertising-agency/",
  },
  {
    id: "5",
    title: "Inflight Branding",
    link: "/inflight-branding-advertising-agency/",
  },
  {
    id: "6",
    title: "Transit Media",
    link: "/transit-media-advertising-agency/",
  },
  {
    id: "7",
    title: "All Hoardings",
    link: "/all-outdoor/",
  },
  {
    id: "8",
    title: "All Hoardings Advertising",
    link: "/outdoor-hoarding-advertising/",
  },
];
