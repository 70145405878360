// import React, { useState, useEffect } from "react";
// // MUI
// import { Box } from "@mui/material";
// // import ;

// // HELMET
// import { Helmet } from "react-helmet";

// // CSS
// import "./Home.css";

// // PAGES 1
// import HomePageBanners from "./HomeComponents2/HomePageBanners/HomePageBanners";
// import Explorecity from "./HomeComponents2/ExploreCity/Explorecity";
// import Weseethings from "./HomeComponents2/WeSeeThings/Weseethings";
// import Triumphs2 from "./HomeComponents2/Triumphs2/Triumphs2";
// import Experience2 from "./HomeComponents2/Experience2/Experience2";
// import Confused from "./HomeComponents2/Confused/Confused";
// import Ourprocess from "./HomeComponents2/OurProcess/Ourprocess";
// import Ourservices from "./HomeComponents2/OurServices/Ourservices";
// import Blogsection from "./HomeComponents2/BlogSection/Blogsection";
// import Topmediaspendsdesktop from "./HomeComponents2/TopMediaSpendsDesktop/Topmediaspendsdesktop";
// import OurservicesMobile from "./HomeComponents2/OurServiceMobile/OurServiceMobile";

// // PAGES 2

// const Home = () => {
//   const [placeholder, setPlaceholder] = useState("");
//   const [index, setIndex] = useState(0);
//   const [textIndex, setTextIndex] = useState(0);

//   const texts = ["unipole", "search", "find", "discover"];
//   const speed = 150;

//   useEffect(() => {
//     if (index < texts[textIndex].length) {
//       const timeout = setTimeout(() => {
//         setPlaceholder(placeholder + texts[textIndex][index]);
//         setIndex(index + 1);
//       }, speed);

//       return () => clearTimeout(timeout);
//     } else {
//       const resetTimeout = setTimeout(() => {
//         setPlaceholder("");
//         setIndex(0);
//         setTextIndex((prevTextIndex) => (prevTextIndex + 1) % texts.length); // Cycle through texts
//       }, 2000);

//       return () => clearTimeout(resetTimeout);
//     }
//   }, [index, placeholder, textIndex]);

//   useEffect(() => {
//     const style = document.createElement("style");
//     style.innerHTML = `
//       .custom-placeholder::placeholder {
//         color: green;
//         opacity: 1;
//       }
//     `;
//     document.head.appendChild(style);
//     return () => {
//       document.head.removeChild(style);
//     };
//   }, []);

//   return (
//     <>
//       <Helmet>
//         <title>
//           JANUSOOH is the India's largest outdoor advertising agency
//         </title>
//         <meta
//           name="description"
//           content={
//             "Discover the power of JANUSOOH, India's largest outdoor advertising agency. Elevate your brand's reach with our innovative solutions and unparalleled expertise."
//           }
//         />
//       </Helmet>

//       {/* Home 1 */}
//       <Box sx={{ marginTop: { xs: "51px", sm: "51px", md: "0px", lg: "0px" } }}>
//         <HomePageBanners />
//         <Weseethings />
//         <OurservicesMobile/>
//         <Ourservices />
//         <Explorecity />
//         <Ourprocess />
//         <Confused />
//         <Experience2 />
//         <Blogsection />
//         <Topmediaspendsdesktop />
//         <Triumphs2 />
//       </Box>
//     </>
//   );
// };

// export default Home;

import React, { useState, useEffect, Suspense } from "react";
// MUI
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// import ;

// HELMET
import { Helmet } from "react-helmet";

// CSS
import "./Home.css";
import Ourprocessmobile from "./HomeComponents2/OurProcessMobile/Ourprocessmobile";

// PAGES 1
// import HomePageBanners from "./HomeComponents2/HomePageBanners/HomePageBanners";
// import Explorecity from "./HomeComponents2/ExploreCity/Explorecity";
// import Weseethings from "./HomeComponents2/WeSeeThings/Weseethings";
// import Triumphs2 from "./HomeComponents2/Triumphs2/Triumphs2";
// import Experience2 from "./HomeComponents2/Experience2/Experience2";
// import Confused from "./HomeComponents2/Confused/Confused";
// import Ourprocess from "./HomeComponents2/OurProcess/Ourprocess";
// import Ourservices from "./HomeComponents2/OurServices/Ourservices";
// import Blogsection from "./HomeComponents2/BlogSection/Blogsection";
// import Topmediaspendsdesktop from "./HomeComponents2/TopMediaSpendsDesktop/Topmediaspendsdesktop";
// import OurservicesMobile from "./HomeComponents2/OurServiceMobile/OurServiceMobile";

const HomePageBanners = React.lazy(() =>
  import("./HomeComponents2/HomePageBanners/HomePageBanners")
);
const Explorecity = React.lazy(() =>
  import("./HomeComponents2/ExploreCity/Explorecity")
);
const Weseethings = React.lazy(() =>
  import("./HomeComponents2/WeSeeThings/Weseethings")
);
const Triumphs2 = React.lazy(() =>
  import("./HomeComponents2/Triumphs2/Triumphs2")
);
const Experience2 = React.lazy(() =>
  import("./HomeComponents2/Experience2/Experience2")
);
const Confused = React.lazy(() =>
  import("./HomeComponents2/Confused/Confused")
);
const Ourprocess = React.lazy(() =>
  import("./HomeComponents2/OurProcess/Ourprocess")
);
const Ourservices = React.lazy(() =>
  import("./HomeComponents2/OurServices/Ourservices")
);
const Blogsection = React.lazy(() =>
  import("./HomeComponents2/BlogSection/Blogsection")
);
const Topmediaspendsdesktop = React.lazy(() =>
  import("./HomeComponents2/TopMediaSpendsDesktop/Topmediaspendsdesktop")
);
const OurservicesMobile = React.lazy(() =>
  import("./HomeComponents2/OurServiceMobile/OurServiceMobile")
);


// PAGES 2

const Home = () => {
  const [placeholder, setPlaceholder] = useState("");
  const [index, setIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);

  const texts = ["unipole", "search", "find", "discover"];
  const speed = 150;

  useEffect(() => {
    if (index < texts[textIndex].length) {
      const timeout = setTimeout(() => {
        setPlaceholder(placeholder + texts[textIndex][index]);
        setIndex(index + 1);
      }, speed);

      return () => clearTimeout(timeout);
    } else {
      const resetTimeout = setTimeout(() => {
        setPlaceholder("");
        setIndex(0);
        setTextIndex((prevTextIndex) => (prevTextIndex + 1) % texts.length); // Cycle through texts
      }, 2000);

      return () => clearTimeout(resetTimeout);
    }
  }, [index, placeholder, textIndex]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-placeholder::placeholder {
        color: green;
        opacity: 1;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          JANUSOOH is the India's largest outdoor advertising agency
        </title>
        <meta
          name="description"
          content={
            "Discover the power of JANUSOOH, India's largest outdoor advertising agency. Elevate your brand's reach with our innovative solutions and unparalleled expertise."
          }
        />
      </Helmet>

      {/* Home 1 */}
      <Box sx={{ marginTop: { xs: "51px", sm: "51px", md: "0px", lg: "0px" } }}>
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop:"500px"
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          <HomePageBanners />
          <Weseethings />
          <OurservicesMobile />
          <Ourservices />
          <Explorecity />
          <Ourprocess />
          <Confused />
          <Experience2 />
          <Blogsection />
          <Topmediaspendsdesktop />
          <Triumphs2 />
        </Suspense>
      </Box>
    </>
  );
};

export default Home;
