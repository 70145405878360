const types = {
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_BY_STATE: "GET_PRODUCTS_BY_STATE",
  POST_CUSTOMER_ENQUIRY: "POST_CUSTOMER_ENQUIRY",
  GET_PRODUCTS_CATEGORIES: "GET_PRODUCTS_CATEGORIES",
  GET_STATE_CITY: "GET_STATE_CITY",
  SELECT_STATE: "SELECT_STATE",
  UPDATE_CITIES: "UPDATE_CITIES",
  SELECT_CITY: "SELECT_CITY",
  GET_BLOG: "GET_BLOG",
  GET_BLOG_BY_ID: "GET_BLOG_BY_ID",
  GET_RELATED_BLOGS: "GET_RELATED_BLOGS",
  GET_TYPE: "GET_TYPE",
  GET_BLOG_ID : "GET_BLOG_ID"
};

export default types;
