export const states = [
  "Andra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadar and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Pondicherry",
];

export const stateDistricts = {
  "Andra Pradesh": [
    "Visakhapatnam",
    "Vijayawada",
    "Guntur",
    "Nellore",
    "Kurnool",
    "Rajahmundry",
    "Kakinada",
    "Tirupati",
    "Anantapur",
    "Kadapa",
    "Eluru",
    "Ongole",
    "Chittoor",
    "Machilipatnam",
    "Srikakulam",
    "Vizianagaram",
    "Adoni",
    "Proddatur",
  ],
  "Arunachal Pradesh": [
    "Itanagar",
    "Naharlagun",
    "Pasighat",
    "Tawang",
    "Bomdila",
    "Ziro",
    "Tezu",
    "Daporijo",
    "Aalo (Along)",
    "Anini",
    "Changlang",
    "Khonsa",
    "Roing",
    "Yingkiong",
    "Seppa",
    "Longding",
  ],
  Assam: [
    "Guwahati",
    "Silchar",
    "Dibrugarh",
    "Jorhat",
    "Nagaon",
    "Tinsukia",
    "Tezpur",
    "Bongaigaon",
    "Sivasagar",
    "Karimganj",
    "Lakhimpur",
    "Goalpara",
    "Golaghat",
    "Kokrajhar",
    "Barpeta",
    "Dhubri",
    "Dhekiajuli",
    "Hailakandi",
    "Morigaon",
    "Nalbari",
    "North Lakhimpur",
    "Rangia",
    "Udalguri",
  ],
  Bihar: [
    "Patna",
    "Gaya",
    "Bhagalpur",
    "Muzaffarpur",
    "Darbhanga",
    "Purnia",
    "Bihar Sharif",
    "Ara",
    "Begusarai",
    "Katihar",
    "Munger",
    "Chhapra",
    "Hajipur",
    "Saharsa",
    "Samastipur",
    "Siwan",
    "Motihari",
    "Nawada",
    "Bettiah",
    "Dehri",
    "Sasaram",
    "Jamalpur",
    "Buxar",
    "Sitamarhi",
    "Jahanabad",
    "Aurangabad",
    "Madhubani",
    "Purnia",
    "Lakhisarai",
    "Supaul",
    "Khagaria",
    "Sheohar",
    "Arwal",
    "Rohtas",
    "Nalanda",
    "Gopalganj",
    "Vaishali",
    "Kishanganj",
    "Araria",
    "Madhepura",
    "Sheikhpura",
    "Kaimur",
    "Banka",
  ],
  Chhattisgarh: [
    "Raipur",
    "Bilaspur",
    "Durg",
    "Bhilai",
    "Korba",
    "Rajnandgaon",
    "Jagdalpur",
    "Raigarh",
    "Ambikapur",
    "Dhamtari",
    "Mahasamund",
    "Champa",
    "Bhatapara",
    "Kawardha",
    "Chirmiri",
    "Dalli-Rajhara",
    "Sakti",
    "Mungeli",
    "Dongargarh",
    "Tilda Newra",
    "Kirandul",
    "Kondagaon",
    "Manendragarh",
    "Surajpur",
    "Gidam",
    "Sukma",
  ],
  Chandigarh: ["Chandigarh"],

  "Dadar and Nagar Haveli": ["Dadra and Nagar Haveli"],

  "Daman and Diu": ["Daman", "Diu"],

  Delhi: [
    "Central Delhi",
    "East Delhi",
    "New Delhi",
    "North Delhi",
    "North East Delhi",
    "North West Delhi",
    "Shahdara",
    "South Delhi",
    "South East Delhi",
    "South West Delhi",
    "West Delhi",
  ],

  Goa: [
    "Panaji",
    "Vasco da Gama",
    "Margao",
    "Mapusa",
    "Ponda",
    "Cortalim",
    "Cuncolim",
    "Sanguem",
    "Quepem",
    "Bicholim",
    "Sanquelim",
    "Pernem",
    "Verna",
    "Salcete",
    "Cortalim",
    "Anjuna",
    "Calangute",
    "Arambol",
    "Candolim",
    "Dona Paula",
    "Aldona",
    "Loutolim",
    "Panjim",
  ],

  Gujarat: [
    "Ahmedabad",
    "Amreli",
    "Anand",
    "Aravalli",
    "Banaskantha",
    "Bharuch",
    "Bhavnagar",
    "Botad",
    "Chhota Udaipur",
    "Dahod",
    "Dang",
    "Devbhoomi Dwarka",
    "Gandhinagar",
    "Gir Somnath",
    "Jamnagar",
    "Junagadh",
    "Kheda",
    "Kutch",
    "Mahisagar",
    "Mehsana",
    "Morbi",
    "Narmada",
    "Navsari",
    "Panchmahal",
    "Patan",
    "Porbandar",
    "Rajkot",
    "Sabarkantha",
    "Surat",
    "Surendranagar",
    "Tapi",
    "Vadodara",
    "Valsad",
  ],

  Haryana: [
    "Chandigarh",
    "Faridabad",
    "Gurugram",
    "Hisar",
    "Rohtak",
    "Karnal",
    "Ambala",
    "Panipat",
    "Sonipat",
    "Jind",
    "Mahendragarh",
    "Bhiwani",
    "Yamunanagar",
    "Sirsa",
    "Fatehabad",
    "Panchkula",
    "Kurukshetra",
    "Palwal",
    "Kaithal",
    "Jammu",
    "Mewat",
    "Rewari",
    "Charkhi Dadri",
    "Shahabad",
    "Hansi",
    "Narwana",
    "Pehowa",
    "Tohana",
  ],

  "Himachal Pradesh": [
    "Shimla",
    "Manali",
    "Dharamshala",
    "Kullu",
    "Solan",
    "Mandi",
    "Hamirpur",
    "Una",
    "Bilaspur",
    "Chamba",
    "Kangra",
    "Palampur",
    "Nahan",
    "Dalhousie",
    "Jwalamukhi",
    "Sundernagar",
    "Arki",
    "Nagrota Bagwan",
    "Pandoh",
    "Swarghat",
    "Rohru",
    "Tissa",
    "Kasauli",
    "Kalpa",
    "Kinnaur",
    "Jubbal",
  ],

  "Jammu and Kashmir": [
    "Jammu",
    "Samba",
    "Kathua",
    "Udhampur",
    "Rajouri",
    "Poonch",
    "Anantnag",
    "Kulgam",
    "Pulwama",
    "Shopian",
    "Srinagar",
    "Budgam",
    "Ganderbal",
  ],

  Jharkhand: [
    "Ranchi",
    "Jamshedpur",
    "Dhanbad",
    "Bokaro Steel City",
    "Hazaribagh",
    "Giridih",
    "Deoghar",
    "Chaibasa",
    "Dumka",
    "Madhupur",
    "Koderma",
    "Jhumri Tilaiya",
    "Ramgarh",
    "Pakur",
    "Jharia",
    "Ghatshila",
    "Bermo",
    "Kolkata",
    "Manoharpur",
    "Barhi",
    "Kanke",
    "Patratu",
    "Kusai",
    "Lohardaga",
    "Simdega",
    "Tata Nagar",
  ],

  Karnataka: [
    "Bengaluru",
    "Mysuru",
    "Hubballi",
    "Dharwad",
    "Mangalore",
    "Belgaum (Belagavi)",
    "Tumakuru",
    "Shivamogga",
    "Chikkamagaluru",
    "Mandya",
    "Udupi",
    "Bagalkot",
    "Hassan",
    "Raichur",
    "Kolar",
    "Bidar",
    "Davangere",
    "Chitradurga",
    "Haveri",
    "Kalaburagi",
    "Gadag",
    "Yadgir",
    "Ramanagara",
    "Koppal",
    "Sagar",
    "Karwar",
    "Virajpet",
    "Puttur",
    "Kumta",
    "Hosapete",
    "Chikkaballapur",
    "Jayanagar",
    "Yeshvantpur",
  ],

  Kerala: [
    "Thiruvananthapuram",
    "Kochi",
    "Kozhikode",
    "Kollam",
    "Kannur",
    "Malappuram",
    "Thrissur",
    "Palakkad",
    "Kottayam",
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Wayanad",
    "Kasargod",
    "Pathanamthitta",
    "Muvattupuzha",
    "Ottapalam",
    "Ponnani",
    "Vatakara",
    "Changanassery",
    "Perinthalmanna",
    "Shoranur",
    "Kattappana",
    "Sreekariyam",
    "Nellayi",
    "Cherthala",
  ],

  "Madhya Pradesh": [
    "Agar Malwa",
    "Alirajpur",
    "Anuppur",
    "Ashoknagar",
    "Balaghat",
    "Barwani",
    "Betul",
    "Bhind",
    "Bhopal",
    "Burhanpur",
    "Chhatarpur",
    "Chhindwara",
    "Damoh",
    "Datia",
    "Dewas",
    "Dhar",
    "Dindori",
    "Guna",
    "Gwalior",
    "Harda",
    "Hoshangabad",
    "Indore",
    "Jabalpur",
    "Jhabua",
    "Katni",
    "Khandwa",
    "Khargone",
    "Mandla",
    "Mandsaur",
    "Morena",
    "Narsinghpur",
    "Neemuch",
    "Panna",
    "Raisen",
    "Rajgarh",
    "Ratlam",
    "Rewa",
    "Sagar",
    "Satna",
    "Sehore",
    "Seoni",
    "Shahdol",
    "Shajapur",
    "Sheopur",
    "Shivpuri",
    "Sidhi",
    "Singrauli",
    "Tikamgarh",
    "Ujjain",
    "Umaria",
    "Vidisha",
  ],

  Maharashtra: [
    "Ahmednagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Beed",
    "Bhandara",
    "Buldhana",
    "Chandrapur",
    "Dhule",
    "Gadchiroli",
    "Gondia",
    "Hingoli",
    "Jalgaon",
    "Jalna",
    "Kolhapur",
    "Latur",
    "Mumbai City",
    "Mumbai Suburban",
    "Nagpur",
    "Nanded",
    "Nandurbar",
    "Nashik",
    "Osmanabad",
    "Palghar",
    "Parbhani",
    "Pune",
    "Raigad",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sindhudurg",
    "Solapur",
    "Thane",
    "Wardha",
    "Washim",
    "Yavatmal",
  ],
  Manipur: [
    "Imphal",
    "Thoubal",
    "Churachandpur",
    "Kakching",
    "Jiribam",
    "Ukhrul",
    "Tamenglong",
    "Senapati",
    "Bishnupur",
    "Noney",
    "Kangpokpi",
    "Kanglatongbi",
    "Moreh",
    "Tengnoupal",
    "Moirang",
    "Pallel",
    "Yairipok",
    "Wangjing",
    "Sugnu",
    "Khongjom",
    "Nambol",
  ],

  Meghalaya: [
    "Shillong",
    "Tura",
    "Nongstoin",
    "Jowai",
    "Baghmara",
    "Williamnagar",
    "Mairang",
    "Resubelpara",
    "Nongpoh",
    "Cherrapunji",
    "Mawkyrwat",
    "Byrnihat",
    "Ampati",
    "Mendipathar",
    "Khliehriat",
    "Umiam",
    "Sohra",
    "Tikrikilla",
    "Ranikor",
  ],

  Mizoram: [
    "Aizawl",
    "Champhai",
    "Hnahthial",
    "Khawzawl",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
  ],

  Nagaland: [
    "Dimapur",
    "Kiphire",
    "Kohima",
    "Longleng",
    "Mokokchung",
    "Mon",
    "Peren",
    "Phek",
    "Tuensang",
    "Wokha",
    "Zunheboto",
  ],

  Odisha: [
    "Bhubaneswar",
    "Cuttack",
    "Rourkela",
    "Brahmapur",
    "Sambalpur",
    "Puri",
    "Balasore",
    "Baripada",
    "Bhadrak",
    "Angul",
    "Dhenkanal",
    "Jharsuguda",
    "Balangir",
    "Koraput",
    "Rayagada",
    "Jeypore",
    "Paradip",
    "Kendujhar",
    "Sonepur",
    "Jagatsinghpur",
    "Kendrapara",
    "Nabarangpur",
    "Malkangiri",
    "Phulbani",
    "Boudh",
    "Deogarh",
    "Nuapada",
    "Ganjam",
    "Bargarh",
    "Nayagarh",
    "Subarnapur",
  ],

  Punjab: [
    "Amritsar",
    "Barnala",
    "Bathinda",
    "Faridkot",
    "Fatehgarh Sahib",
    "Fazilka",
    "Ferozepur",
    "Gurdaspur",
    "Hoshiarpur",
    "Jalandhar",
    "Kapurthala",
    "Ludhiana",
    "Mansa",
    "Moga",
    "Muktsar",
    "Pathankot",
    "Patiala",
    "Rupnagar",
    "Sangrur",
    "Shaheed Bhagat Singh Nagar",
    "Sri Muktsar Sahib",
    "Tarn Taran",
  ],

  Rajasthan: [
    "Ajmer",
    "Alwar",
    "Banswara",
    "Baran",
    "Barmer",
    "Bharatpur",
    "Bhilwara",
    "Bikaner",
    "Bundi",
    "Chittorgarh",
    "Churu",
    "Dausa",
    "Dholpur",
    "Dungarpur",
    "Hanumangarh",
    "Jaipur",
    "Jaisalmer",
    "Jalore",
    "Jhalawar",
    "Jhunjhunu",
    "Jodhpur",
    "Karauli",
    "Kota",
    "Nagaur",
    "Pali",
    "Pratapgarh",
    "Rajsamand",
    "Sawai Madhopur",
    "Sikar",
    "Sirohi",
    "Sri Ganganagar",
    "Tonk",
    "Udaipur",
  ],

  Sikkim: [
    "Gangtok",
    "Namchi",
    "Geyzing",
    "Mangan",
    "Rangpo",
    "Jorethang",
    "Pelling",
    "Ravangla",
    "Singtam",
    "Yuksom",
    "Zuluk",
    "Lachung",
    "Lachen",
    "Soreng",
    "Chungthang",
    "Rinchenpong",
    "Namthang",
    "Pakyong",
    "Dentam",
    "Tadong",
  ],

  "Tamil Nadu": [
    "Ariyalur",
    "Chengalpattu",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kallakurichi",
    "Kancheepuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Mayiladuthurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Ranipet",
    "Salem",
    "Sivaganga",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tirupathur",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ],

  Telangana: [
    "Adilabad",
    "Bhadradri Kothagudem",
    "Hyderabad",
    "Jagtial",
    "Jangaon",
    "Jayashankar Bhupalpally",
    "Jogulamba Gadwal",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Komaram Bheem",
    "Mahabubabad",
    "Mahabubnagar",
    "Mancherial",
    "Medak",
    "Medchal Malkajgiri",
    "Nagarkurnool",
    "Nalgonda",
    "Nirmal",
    "Nizamabad",
    "Peddapalli",
    "Rajanna Sircilla",
    "Rangareddy",
    "Sangareddy",
    "Siddipet",
    "Suryapet",
    "Vikarabad",
    "Wanaparthy",
    "Warangal Rural",
    "Warangal Urban",
    "Yadadri Bhuvanagiri",
  ],

  Tripura: [
    "Agartala",
    "Udaipur",
    "Kailashahar",
    "Dharmanagar",
    "Ambassa",
    "Belonia",
    "Khowai",
    "Sabroom",
    "Sonamura",
    "Teliamura",
    "Bishalgarh",
    "Mohanpur",
    "Ranirbazar",
    "Kumarghat",
    "Amarpur",
    "Melaghar",
    "Panisagar",
    "Kamalpur",
    "Santirbazar",
    "Gandhigram"
  ],
  "Uttar Pradesh": [
    "Lucknow",
    "Kanpur",
    "Ghaziabad",
    "Agra",
    "Varanasi",
    "Meerut",
    "Prayagraj (Allahabad)",
    "Bareilly",
    "Aligarh",
    "Moradabad",
    "Saharanpur",
    "Gorakhpur",
    "Noida",
    "Firozabad",
    "Jhansi",
    "Muzaffarnagar",
    "Mathura",
    "Budaun",
    "Rampur",
    "Shahjahanpur",
    "Faizabad",
    "Farrukhabad",
    "Sultanpur",
    "Etawah",
    "Mirzapur",
    "Bulandshahr",
    "Raebareli",
    "Mau",
    "Sitapur",
    "Etah",
    "Deoria",
    "Mainpuri",
    "Jaunpur",
    "Lakhimpur",
    "Hapur",
    "Barabanki",
    "Orai",
    "Unnao",
    "Banda",
    "Balrampur",
    "Bahraich",
    "Bijnor",
    "Gonda",
    "Sambhal",
    "Azamgarh",
    "Ghazipur",
    "Siddharthnagar",
    "Maharajganj",
    "Amroha",
    "Auraiya",
    "Hardoi",
    "Kasganj",
    "Chitrakoot",
    "Sant Kabir Nagar",
    "Pilibhit",
    "Kannauj",
    "Lalitpur",
    "Fatehpur",
    "Pratapgarh",
    "Amethi",
    "Kaushambi",
    "Bagpat",
    "Ambedkar Nagar",
    "Shrawasti"
  ],
  Uttarakhand: [
    "Dehradun",
    "Haridwar",
    "Rishikesh",
    "Roorkee",
    "Haldwani",
    "Nainital",
    "Almora",
    "Pithoragarh",
    "Kashipur",
    "Rudrapur",
    "Ranikhet",
    "Mussoorie",
    "Kotdwara",
    "Srinagar",
    "Pauri",
    "Chamoli",
    "Tehri",
    "Bageshwar",
    "Champawat",
    "Bhimtal",
    "Uttarkashi",
    "Lansdowne",
    "Doiwala",
    "Manglaur",
    "Kichha",
    "Jaspur",
    "Sitarganj",
    "Chamba",
    "Ramgarh",
    "Lohaghat",
    "Pauri Garhwal",
    "Barkot",
    "Tanakpur",
    "Joshimath",
    "Narendranagar",
    "Harsil",
    "Chakrata"
  ],

  "West Bengal": [
    "Alipurduar",
    "Bankura",
    "Birbhum",
    "Cooch Behar",
    "Dakshin Dinajpur",
    "Darjeeling",
    "Hooghly",
    "Howrah",
    "Jalpaiguri",
    "Jhargram",
    "Kalimpong",
    "Kolkata",
    "Malda",
    "Murshidabad",
    "Nadia",
    "North 24 Parganas",
    "Paschim Bardhaman",
    "Paschim Medinipur",
    "Purba Bardhaman",
    "Purba Medinipur",
    "Purulia",
    "South 24 Parganas",
    "Uttar Dinajpur",
  ],

  "Andaman and Nicobar Islands": [
    "Nicobar",
    "North and Middle Andaman",
    "South Andaman",
  ],

  Lakshadweep: ["Lakshadweep"],

  Pondicherry: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
};
