import React, { useEffect, useState } from "react";

// MATERIAL-UI
import { Typography, Grid, Box, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";

import { toast, ToastContainer } from "react-toastify";

// IMAGES
import closebtn from "../../assets/images/close.png";
import call from "../../assets/images/call.png";
import mail from "../../assets/images/mail.png";

// REACT-ROUTER-DOM
import { useLocation } from "react-router-dom";

import "./Popupcomponent.css";
import {
  CloseButtonStyle,
  PopupContentAlignment,
  PopupEmailField,
  PopupNameInputField,
  PopupPage,
  PopUpRequestMessage,
  RequestCallText,
} from "../Styles/Styles";

const Popupcomponent = ({ ProductUniqueCode, ProductId, ...props }) => {
  const location = useLocation();
  useEffect(() => {
    console.log(location);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    uniquecode: ProductUniqueCode,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://www.januskoncepts.in/janusoohadmin/api/callback/callback.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(formData).toString(), // Send formData with uniquecode
        }
      );

      if (response) {
        toast.success("Request submitted successfully!");
        // Reset form
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          uniquecode: ProductUniqueCode, // Reset ProductUniqueCode
        });
      } else {
        toast.error("Form submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Form submission failed. Please try again.");
    }
  };

  return (
    <Box sx={PopupPage}>
      <Box sx={PopupContentAlignment}>
        {/* CloseButton */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            component="img"
            src={closebtn}
            onClick={props.handleClose}
            sx={CloseButtonStyle}
          />
        </Box>

        {/* Request A callback Text */}
        <Grid container bgcolor={""}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={RequestCallText}>Request a Call</Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Inputs */}
        <Box
          sx={{
            marginTop: { xs: "15px", sm: "15px", md: "15px", lg: "15px" },
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                lg={11}
                // sx={{ backgroundColor: "green" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* Name */}
                  <Box>
                    <TextField
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      placeholder="Name"
                      className="inputField"
                      autoComplete="off"
                      id="standard-basic"
                      variant="filled"
                      sx={{
                        width: {
                          xs: "300px",
                          sm: "300px",
                          md: "400px",
                          lg: "400px",
                        },
                      }}
                    />
                  </Box>
                  {/* Email */}
                  <Box
                    sx={{
                      marginTop: {
                        xs: "25px",
                        sm: "25px",
                        md: "20px",
                        lg: "20px",
                      },
                    }}
                  >
                    <TextField
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      placeholder="Email"
                      className="inputField"
                      autoComplete="off"
                      // sx={PopupEmailField}
                      id="standard-basic"
                      variant="filled"
                      sx={{
                        width: {
                          xs: "300px",
                          sm: "300px",
                          md: "400px",
                          lg: "400px",
                        },
                      }}
                    />
                  </Box>
                  {/* Phones */}
                  <Box
                    sx={{
                      marginTop: {
                        xs: "25px",
                        sm: "25px",
                        md: "20px",
                        lg: "20px",
                      },
                    }}
                  >
                    <TextField
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      placeholder="Phone"
                      className="inputField"
                      autoComplete="off"
                      // style={PopupEmailField}
                      id="standard-basic"
                      variant="filled"
                      sx={{
                        width: {
                          xs: "300px",
                          sm: "300px",
                          md: "400px",
                          lg: "400px",
                        },
                      }}
                    />
                  </Box>
                  {/* message */}
                  <Box
                    sx={{
                      marginTop: {
                        xs: "25px",
                        sm: "25px",
                        md: "20px",
                        lg: "20px",
                      },
                    }}
                  >
                    <TextField
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      placeholder="Message"
                      className="inputField"
                      autoComplete="off"
                      // style={PopUpRequestMessage}
                      id="standard-basic"
                      variant="filled"
                      sx={{
                        width: {
                          xs: "300px",
                          sm: "300px",
                          md: "400px",
                          lg: "400px",
                        },
                      }}
                    />
                  </Box>
                  {/* submit Button */}
                  <Box
                    sx={{
                      marginTop: {
                        xs: "20px",
                        sm: "20px",
                        md: "20px",
                        lg: "20px",
                      },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "black",
                        width: "130px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "35px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Button
                        sx={{
                          color: "#fff",
                          paddingLeft: "10px",
                          textTransform: "none",
                        }}
                        endIcon={<SendIcon />}
                        type="submit"
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "16px",
                              lg: "16px",
                            },
                            fontFamily: "Inter, sans-serif",
                            fontWeight: "500",
                          }}
                        >
                          Submit
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                  {/* address */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      },
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: {
                        xs:"300px",
                        sm:"300px",
                        md:"400px",
                        lg:"400px"
                      },
                      marginTop: {
                        xs: "15px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                    }}
                  >
                    {/* phoneno */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={call}
                        style={{ width: "18px", height: "18px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "13px",
                            lg: "13px",
                          },
                          fontWeight: "600",
                          fontFamily: "Inter, sans-serif",
                          paddingLeft: { xs: "5px" },
                          color: "#FF1F2F",
                        }}
                      >
                        +91 96507-64004
                      </Typography>
                    </Box>
                    {/* Email address */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: {
                          xs: "5px",
                          sm: "5px",
                          md: "0px",
                          lg: "0px",
                        },
                      }}
                    >
                      <img
                        src={mail}
                        style={{ width: "18px", height: "18px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "13px",
                            lg: "13px",
                          },
                          fontFamily: "Inter, sans-serif",
                          paddingLeft: { xs: "5px" },
                          color: "#FF1F2F",
                          fontWeight: "600",
                        }}
                      >
                        birender@januskoncepts.com
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Popupcomponent;
