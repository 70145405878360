import React from "react";
import { Box, Grid, Typography } from "../../DesignData/MaterialUi/material-ui";

import {
  MainBox,
  cardImage,
  Maintitle,
  TextBox,
  TitleBox,
  Alignment,
  ColonsStyle,
  StateAndCity,
  StateAndCityName,
  StateAndCityWidth,
  StateAndCityNameWidth,
  ColonsWidth,
  CardsMarginForMobileView,
} from "../Styles/Styles";

import { imageNotFound } from "../../DesignData/Images/Images";

// CSS
import "./Cards.css";

const Cards = ({ data, onClick }) => {
  // Check if the product status is inactive
  if (data?.status?.toLowerCase() === "inactive") {
    return null;
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4} sx={CardsMarginForMobileView}>
        <Box
          sx={MainBox}
          onClick={() => {
            onClick();
          }}
        >
          <Box sx={{ position: "relative" }}>
            {/* Image */}
            <Box
              component="img"
              sx={cardImage}
              alt="card"
              src={data?.image ? data.image : imageNotFound}
            />
            <Box>
              {/* Red box Subcategory name */}
              <Box
                sx={{
                  bottom: "-8px",
                  left: "10px",
                  position: "absolute",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter, sans-serif",
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "18px",
                    },
                    fontWeight: "600",
                    color: "white",
                    height: "20px",
                    width: "100%",
                    backgroundColor: "#FF1F2F",
                    borderRadius: "3px",
                    padding: "15px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {data?.sub_category_name
                    ? data.sub_category_name.toLowerCase() === "dooh"
                      ? data.sub_category_name.toUpperCase()
                      : data.sub_category_name.charAt(0).toUpperCase() +
                        data.sub_category_name.slice(1).toLowerCase()
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={TextBox}>
            <Box sx={TitleBox}>
              <Typography sx={Maintitle}>
                {data?.product_name
                  ? data.product_name.charAt(0).toUpperCase() +
                    data.product_name.slice(1).toLowerCase()
                  : ""}
              </Typography>
              <Box
                sx={{
                  marginTop: {
                    xs: "10px",
                    sm: "10px",
                    md: "10px",
                    lg: "10px",
                  },
                  // backgroundColor: "blue",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  {/* City */}
                  <Box sx={Alignment}>
                    <Box sx={StateAndCityWidth}>
                      <Typography sx={StateAndCity}>City</Typography>
                    </Box>
                    <Box sx={ColonsWidth}>
                      <Typography sx={ColonsStyle}>:</Typography>
                    </Box>
                    <Box sx={StateAndCityNameWidth}>
                      <Typography sx={StateAndCityName}>
                        {data.city_name}
                      </Typography>
                    </Box>
                  </Box>

                  {/* State */}
                  <Box sx={Alignment}>
                    <Box sx={StateAndCityWidth}>
                      <Typography sx={StateAndCity}>State</Typography>
                    </Box>
                    <Box sx={ColonsWidth}>
                      <Typography sx={ColonsStyle}>:</Typography>
                    </Box>
                    <Box sx={StateAndCityNameWidth}>
                      <Typography sx={StateAndCityName}>
                        {data?.state_name?.toLowerCase()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Add to cart button */}
                <Box
                  sx={{
                    backgroundColor: "#FF1F2F",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderRadius: "10px",
                    height: "35px",
                    ":hover": {
                      backgroundColor: "#e0000f",
                    },
                    boxShadow:"4"
                  }}
                >
                  <Typography
                    sx={{
                      padding: "8px",
                      color: "white",
                      fontSize: "15px",
                      fontWeight: "500",
                      ":hover": {
                        color: "white",
                      },
                    }}
                  >
                    Add to cart
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Cards;
