/* eslint-disable jsx-a11y/alt-text */
import React from "react";

// MUI
import { Grid, Typography, Button, Box } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { stateDistricts } from "../../assets/json/statesCity";

import worldimg from "../../assets/images/OutdootAdvertising.jpeg";

// import videotwo from "../../../assets/video/VideoWithoutText.mp4";
import videotwo from "../../assets/video/VideoWithoutText.mp4";

import { Helmet } from "react-helmet";
const Alloutoptional = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Outdoor Advertising Agency: Boost Your Brand Visibility</title>
        <meta
          name="description"
          content={
            "Enhance brand visibility with our outdoor advertising agency. Reach wider audiences and amplify your message. Contact us today"
          }
        />
      </Helmet>

      {/* Video */}
      <Box
        sx={{
          marginTop: { xs: "51px", sm: "51px", md: "0px", lg: "0px" },
        }}
      >
        {/* VIDEO */}
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            // display={{ xs: "none", lg: "block" }}
          >
            <Box sx={{ position: "relative" }}>
              {/* Video with gradient overlay */}
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                  height: { xs: "30vh", sm: "30vh", md: "60vh", lg: "60vh" },
                  position: "relative", // Make this box relative for absolute positioning of the gradient
                }}
              >
                {/* Video Element */}
                <video
                  src={videotwo}
                  autoPlay
                  loop
                  muted
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />

                {/* Gradient Overlay */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
                    zIndex: 1, // Ensures gradient is above the video
                  }}
                />
              </Box>

              {/* Text */}
              <Grid
                container
                sx={{
                  position: "absolute",
                  bottom: {
                    xs: "35%",
                    sm: "35%",
                    md: "35%",
                    lg: "35%",
                  },
                  zIndex: 2, // Ensures text is above the gradient
                }}
              >
                <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>

                <Grid item xs={12} sm={12} md={11} lg={11}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Desktop Title */}
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          fontSize: "50px",
                          fontWeight: "50px",
                          fontFamily: "Inter, sans-serif",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        All&nbsp;
                        <span style={{ color: "#FF1F2F" }}>Hoarding Advertising</span>
                      </Typography>
                    </Box>

                    {/* Mobile Title */}
                    <Box
                      sx={{
                        display: {
                          md: "none",
                          lg: "none",
                        },
                        height: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "30px",
                          fontWeight: "500",
                          fontFamily: "Inter, sans-serif",
                          color: "#fff",
                          textAlign: "center",
                          lineHeight: "35px",
                        }}
                      >
                        All &nbsp;
                        <span
                          style={{
                            color: "#FF1F2F",
                            fontSize: "30px",
                            fontWeight: "600",
                          }}
                        >
                          Hoarding <br/>Advertising
                        </span>
                      </Typography>
                    </Box>

                    {/* Desktop Description */}
                    <Box
                      sx={{
                        marginTop: {
                          xs: "10px",
                          sm: "10px",
                          md: "0px",
                          lg: "0px",
                        },
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                        padding: "0px 150px 0px 150px",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "17px",
                          fontWeight: "500",
                          fontFamily: "Inter, sans-serif",
                          color: "#fff",
                          textAlign: "center",
                          lineHeight: "25px",
                        }}
                      >
                        {/* Leverage lifeline of the city */}
                      </Typography>
                    </Box>

                    {/* Mobile Desciption */}
                    <Box
                      sx={{
                        marginTop: "15px",
                        display: {
                          md: "none",
                          lg: "none",
                        },
                        padding: "0px 20px 0px 20px",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "15px",
                          fontWeight: "500",
                          fontFamily: "Inter, sans-serif",
                          color: "#fff",
                          marginTop: "-5px",
                          lineHeight: "20px",
                          textAlign: "center",
                        }}
                      >
                        {/* Leverage lifeline of the city */}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: { xs: "5px", sm: "5px", md: "30px", lg: "30px" } }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={0.7} lg={0.7}></Grid>
          <Grid item xs={12} sm={12} md={10.6} lg={10.6}>
            <Grid
              container
              sx={{
                marginTop: {
                  xs: "50px",
                  sm: "50px",
                  md: "30px",
                  lg: "30px",
                },
              }}
            >
              {Object.keys(stateDistricts).map((item) => (
                <Grid item xs={12} sm={12} md={4} lg={4} sx={{}}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "10px",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      marginBottom: "50px",
                      marginRight: "20px",
                      marginLeft: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // navigate(`/cities/outdoor-hoarding-advertising-agency-in-${item.toLowerCase().replaceAll(' ','-')}`);
                      navigate(
                        `/${item
                          .toLowerCase()
                          .replaceAll(
                            " ",
                            "-"
                          )}-outdoor-hoarding-advertising-agencies`,
                        { state: { AllCities: stateDistricts[item] } }
                      );
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        height: "160px",
                        width: "100%",
                        backgroundColor: "black",
                        opacity: "0.7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontWeight: "700",
                        fontSize: "40px",
                        fontFamily: "Inter, sans-serif",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                    <img
                      src={worldimg}
                      style={{
                        height: "160px",
                        width: "100%",
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={0.7} lg={0.7}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Alloutoptional;
