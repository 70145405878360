import React, { useState, useEffect } from "react";

// Material Ui
import {
  Box,
  Grid,
  ButtonBase,
  AppBar,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Menu,
  Typography,
  MenuIcon,
  Button,
  Modal,
  CircularProgress,
  MenuItem,
} from "../../DesignData/MaterialUi/material-ui";

// IMAGES
import {
  JanusOohLogo,
  DropdownArrow,
  EmptyProfileImg,
  SideDrawerCloseIcon,
  AuthFormImage,
  GoogleLogIn,
  loginProfile,
  cart,
  newImageforCart,
  newImageForCart2,
} from "../../DesignData/Images/Images";

// REACT_ROUTER_DOM
import { useNavigate } from "react-router-dom";

// Side Drawer Content
import { SideDrawerContent } from "../../assets/json/newDesign/SideDrawerContent";

// CategoriesDropdown
import { CategoriesDropdown } from "../../assets/json/newDesign/CategoriesDropdown";

// CSS
import "./Header.css";

import {
  HeaderBGcolor,
  HeaderCategories,
  HeaderCategoriesBox,
  HeaderLogoAlignMent,
  HeaderLogoAndNavigators,
  HeaderLogoStyle,
  HeaderNavigatorsStyle,
  SideDrawerIcon,
  SideDrawerIconsAndTextAlign,
  SideDrawerLogoAndCloseButtonAlign,
  SideDrawerTextStyle,
  SiderDrawerCloseIconStyle,
  SiderDrawerLogoStyle,
  AlreadyAndHaveNot,
  AuthButtonText,
  AuthFormButtonStyle,
  AuthFormImageAlignment,
  AuthFormImageStyle,
  AuthFormLoginInputsAlign,
  AuthFormSignUpInputs,
  AuthFormSignUpInputsAlignNameAndCity,
  AuthFormSignUpInputsAlignPhoneAndEmail,
  AuthFormSignUpInputsAlignStateandCity,
  AuthFormSignUpInputsForMobile,
  AuthModalLoginstyle,
  AuthModalstyle,
  FillUpFor,
  GooglelogoStyle,
  InputsLeftandRightPadding,
  LoginpageSubmitButtonAlign,
  LoginpageSubmitButtonStyle,
  loginwithGmailbuttonAlign,
  loginwithGmailbuttonStyle,
  LoginwithGmailText,
  ortextStyle,
  PasswordInputAlign,
  RegistrationAndLoginText,
  RegistrationStateAndCityStyle,
  RegistrationStateAndCityStyleForMobile,
  RegistrationSubmitButtonAlign,
  RegistrationSubmitbuttonStyle,
  CartModalstyle,
} from "../../pages/Styles/Styles";

const drawerWidth = 240;

function Header(props) {
  // Login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Profile Dropdown
  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuOpen = Boolean(menuAnchor);

  const handleImageClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  // Cartmodal
  // Add to cart
  const [opencartmodal, setOpencartmodal] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [showContent2, setShowContent2] = useState(true);
  const [showContent3, setShowContent3] = useState(true);

  const handleCartmodalOpen = () => setOpencartmodal(true);

  const handleCartmodalClose = () => setOpencartmodal(false);

  const handleCrossClick = () => {
    setShowContent(false);
  };

  const handleCrossClick2 = () => {
    setShowContent2(false);
  };

  const handleCrossClick3 = () => {
    setShowContent3(false);
  };

  // cart modal ends

  // AUTH
  const [open, setOpen] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toggleForm = () => setIsSignUp(!isSignUp);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    password: "",
  });

  const [stateData, setStateData] = useState(null);
  const [cityData, setCityData] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedState(event.target.value);

    fetch(
      "https://www.januskoncepts.in/janusoohadmin/api/stateandcity/location.php?state_id=" +
        event.target.value
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("cityData", responseJson);
        setCityData(responseJson);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // Login
  const handleLogin = async (e) => {
    e.preventDefault();

    console.log(email, password);

    try {
      const response = await fetch(
        "https://www.januskoncepts.in/janusoohadmin/api/loginform/login.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log("Login successful:", data);
        localStorage.setItem("registrationData", JSON.stringify(data));
        setFormSubmitted(data);
        handleClose();
        if (data.token) {
          localStorage.setItem("token", data.token);
        }
        setEmail("");
        setPassword("");
      } else {
        console.error("Login failed:", data);
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
    }
  };
  // Logout
  const handleLogout = async () => {
    try {
      const response = await fetch(
        "https://www.januskoncepts.in/janusoohadmin/api/loginform/logout.php",
        {
          method: "POST",
        }
      );

      localStorage.removeItem("jwtToken");
      localStorage.removeItem("registrationData");
      setFormSubmitted(null);

      if (response.ok) {
        console.log("Logged out successfully");
      } else {
        console.error("Logout failed:", await response.text());
      }
    } catch (error) {
      console.error("There was an error with the logout:", error);
    }
  };
  // Registration
  const handleSubmit = async () => {
    setIsSubmitting(true);

    const fullFormData = {
      ...formData,
      state: selectedState,
      city: selectedCity,
    };

    try {
      const response = await fetch(
        "https://www.januskoncepts.in/janusoohadmin/api/loginform/registration_form.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(fullFormData).toString(),
        }
      );

      const result = await response.json();
      console.log("API Response:", result);

      if (result.message === "New form submission successfully") {
        setIsSubmitted(true);

        localStorage.setItem("registrationData", JSON.stringify(fullFormData));
        if (result.token) {
          localStorage.setItem("jwtToken", result.token);
        }

        console.log("Data saved to localStorage:", fullFormData);
        const Value = localStorage.getItem("registrationData");
        console.log("TOKEN", Value);
        setFormSubmitted(Value);
        handleClose();
      } else {
        setIsSubmitted(false);
        console.error("Registration failed:", result.message);
      }
    } catch (error) {
      setIsSubmitted(false);
      console.error("There was an error with the registration:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const Value = localStorage.getItem("registrationData");
    console.log("TOKEN", Value);
    setFormSubmitted(Value);
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        company: "",
        password: "",
      });
      setSelectedState("");
      setSelectedCity("");
      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  useEffect(() => {
    fetch(
      "https://www.januskoncepts.in/janusoohadmin/api/stateandcity/location.php"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("state and city", responseJson);
        setStateData(responseJson);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  // Auth ends

  // dropdwon
  const [anchorEl, setAnchorEl] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // dropdwon

  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // Mobile side bar navigator
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        backgroundColor: "#fff",
        height: "100%",
        position: "relative",
      }}
    >
      <Box sx={SideDrawerLogoAndCloseButtonAlign}>
        {/* Logo */}
        <Box
          component="img"
          sx={SiderDrawerLogoStyle}
          alt="janusLogo"
          src={JanusOohLogo}
          onClick={() => navigate("/")}
        />
        {/* CloseIcon */}
        <Box
          component="img"
          src={SideDrawerCloseIcon}
          sx={SiderDrawerCloseIconStyle}
        />
      </Box>

      <Box sx={{ marginTop: "30px" }}>
        {/* Home */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {SideDrawerContent.map((item, index) => (
            <Box
              sx={SideDrawerIconsAndTextAlign}
              onClick={() => navigate(item.link)}
            >
              <Box component="img" src={item.image} style={SideDrawerIcon} />
              <Box sx={{ marginLeft: "15px" }}>
                <Typography sx={SideDrawerTextStyle}>{item.title}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={HeaderBGcolor} elevation={0}>
        <Toolbar>
          {/* Header Logo and navigation texts */}
          <Box sx={HeaderLogoAndNavigators}>
            {/* Janus Logo Desktop */}
            <Box sx={HeaderLogoAlignMent}>
              <Box
                component="img"
                sx={HeaderLogoStyle}
                alt="janusLogo"
                src={JanusOohLogo}
                onClick={() => navigate("/")}
              />
            </Box>

            {/* headers navigators */}
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: {
                    xs: "0px",
                    sm: "0px",
                    md: "40px",
                    lg: "40px",
                  },
                }}
              >
                {/* Home*/}
                <Box>
                  <Typography
                    sx={HeaderNavigatorsStyle}
                    onClick={() => navigate("/")}
                  >
                    HOME
                  </Typography>
                </Box>

                {/* About us */}
                <Box sx={{ paddingLeft: "30px" }}>
                  <Typography
                    sx={HeaderNavigatorsStyle}
                    onClick={() => navigate("/about-us/")}
                  >
                    ABOUT US
                  </Typography>
                </Box>

                {/* Our Services - Dropdown starts */}
                <Box sx={{ paddingLeft: "30px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={HeaderNavigatorsStyle}
                        onMouseOver={handleOpenMenu}
                      >
                        OUR SERVICES
                      </Typography>
                    </Box>

                    {/* DropdownArrow image */}
                    <Box
                      component="img"
                      src={DropdownArrow}
                      style={{
                        width: "10px",
                        height: "5px",
                        marginLeft: "5px",
                        marginBottom: "2px",
                      }}
                    />
                  </Box>

                  <Menu
                    sx={{ marginTop: "22px" }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    MenuListProps={{ onMouseLeave: handleCloseMenu }}
                  >
                    <Box sx={{ width: "100%", borderRadius: "0px" }}>
                      {CategoriesDropdown.map((item, index) => (
                        <Box
                          onClick={() => navigate(item.link)}
                          index={item.index}
                          sx={HeaderCategoriesBox}
                        >
                          <Typography sx={HeaderCategories}>
                            {item.title}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Menu>
                </Box>

                {/* Blogs */}
                <Box sx={{ paddingLeft: "30px" }}>
                  <Typography
                    sx={HeaderNavigatorsStyle}
                    onClick={() => navigate("/blogs/")}
                  >
                    BLOGS
                  </Typography>
                </Box>

                {/* Contact us */}
                <Box sx={{ paddingLeft: "30px" }}>
                  <Typography
                    sx={HeaderNavigatorsStyle}
                    onClick={() => navigate("/contact-us/")}
                  >
                    CONTACT US
                  </Typography>
                  {/* </Button> */}
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Mobile Hamburger logo */}

          {/* Profile Image */}
          {formSubmitted ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
            <Box
                component="img"
                src={cart}
                sx={{
                  width: "25px",
                  height: "25px",
                  cursor: {
                    xs: "none",
                    sm: "none",
                    md: "pointer",
                    lg: "pointer",
                  },
                  marginRight: "20px",
                }}
                onClick={handleCartmodalOpen}
              />
              <Box
                component="img"
                src={loginProfile}
                onClick={handleImageClick}
                sx={{
                  width: "30px",
                  height: "30px",
                  cursor: {
                    xs: "none",
                    sm: "none",
                    md: "pointer",
                    lg: "pointer",
                  },
                  marginRight: "20px",
                }}
              />
              
            </Box>
          ) : (
            <Box
              component="img"
              src={EmptyProfileImg}
              sx={{
                width: "30px",
                height: "30px",
                cursor: {
                  xs: "none",
                  sm: "none",
                  md: "pointer",
                  lg: "pointer",
                },
                marginRight: "20px",
              }}
              onClick={handleOpen}
            />
          )}

          <Menu
            id="simple-menu"
            anchorEl={menuAnchor}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem>Profile</MenuItem>
            <MenuItem>
              <Button onClick={handleLogout}>Logout</Button>
            </MenuItem>
          </Menu>

          {/* Hamburger */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" }, marginRight: "0px" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Auth Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {!isSignUp ? (
          <>
            <Grid container sx={AuthModalstyle}>
              <Grid
                item
                xs={0}
                sm={1}
                md={1}
                lg={1}
                // sx={{ backgroundColor: "orange" }}
              ></Grid>

              <Grid
                item
                xs={12}
                sm={10}
                md={10}
                lg={10}
                // sx={{ backgroundColor: "pink" }}
              >
                {/* Title and Subtitles */}
                <Box
                  sx={{
                    marginTop: {
                      xs: "20px",
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                    },
                  }}
                >
                  <Typography sx={FillUpFor}>FILL UP FOR</Typography>
                  <Typography sx={RegistrationAndLoginText}>
                    REGISTRATION
                  </Typography>
                </Box>

                {/* Images */}
                <Box sx={AuthFormImageAlignment}>
                  <Box
                    component="img"
                    src={AuthFormImage}
                    sx={AuthFormImageStyle}
                  />
                </Box>

                {/* Inputs for desktop*/}
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                  }}
                >
                  <Box sx={InputsLeftandRightPadding}>
                    <Grid container>
                      {/* Name and Email */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        // sx={{ backgroundColor: "red" }}
                      >
                        <Box sx={AuthFormSignUpInputsAlignNameAndCity}>
                          {/* Name */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "0px",
                                sm: "10px",
                                md: "5px",
                                lg: "5px",
                              },
                            }}
                          >
                            <input
                              name="name"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleChange}
                              autoComplete="off"
                              style={AuthFormSignUpInputs}
                            />
                          </Box>

                          {/* Email */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "5px",
                                lg: "5px",
                              },
                            }}
                          >
                            <input
                              name="email"
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleChange}
                              autoComplete="off"
                              style={AuthFormSignUpInputs}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      {/* Phone and Company */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        // sx={{ backgroundColor: "red" }}
                      >
                        <Box sx={AuthFormSignUpInputsAlignPhoneAndEmail}>
                          {/* Phone */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                              },
                            }}
                          >
                            <input
                              name="phone"
                              placeholder="Phone"
                              value={formData.phone}
                              onChange={handleChange}
                              autoComplete="off"
                              style={AuthFormSignUpInputs}
                            />
                          </Box>

                          {/* Company */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                              },
                            }}
                          >
                            <input
                              name="company"
                              placeholder="Company"
                              value={formData.company}
                              onChange={handleChange}
                              autoComplete="off"
                              style={AuthFormSignUpInputs}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      {/* State and City */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        // sx={{ backgroundColor: "red" }}
                      >
                        <Box sx={AuthFormSignUpInputsAlignStateandCity}>
                          {/* State */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                              },
                            }}
                          >
                            <select
                              id="dropdown"
                              style={RegistrationStateAndCityStyle}
                              value={selectedState}
                              onChange={handleSelectChange}
                            >
                              <option
                                style={{
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "20px",
                                }}
                              >
                                State
                              </option>
                              {stateData &&
                                stateData.map((item) => (
                                  <option
                                    key={item?.id}
                                    style={{
                                      fontFamily: "Inter, sans-serif",
                                      fontSize: "16px",
                                    }}
                                    value={item?.id}
                                  >
                                    {item?.name.charAt(0).toUpperCase() +
                                      item?.name.slice(1).toLowerCase()}
                                  </option>
                                ))}
                            </select>
                          </Box>

                          {/* City */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                              },
                            }}
                          >
                            <select
                              id="dropdown"
                              style={RegistrationStateAndCityStyle}
                              value={selectedCity}
                              onChange={(event) =>
                                setSelectedCity(event.target.value)
                              }
                            >
                              <option
                                style={{
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "20px",
                                }}
                              >
                                City
                              </option>
                              {cityData &&
                                cityData.map((item) => (
                                  <option
                                    key={item?.id}
                                    style={{
                                      fontFamily: "Inter, sans-serif",
                                      fontSize: "16px",
                                    }}
                                    value={item?.id}
                                  >
                                    {item?.city}
                                  </option>
                                ))}
                            </select>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {/* Inputs for Mobile*/}
                <Box
                  sx={{
                    display: {
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Box sx={InputsLeftandRightPadding}>
                    <Grid container>
                      {/* Name and Email */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        // sx={{ backgroundColor: "red" }}
                      >
                        <Box sx={AuthFormSignUpInputsAlignNameAndCity}>
                          {/* Name */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "0px",
                                sm: "10px",
                                md: "5px",
                                lg: "5px",
                              },
                            }}
                          >
                            <input
                              name="name"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleChange}
                              autoComplete="off"
                              style={AuthFormSignUpInputsForMobile}
                            />
                          </Box>

                          {/* Email */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "5px",
                                lg: "5px",
                              },
                            }}
                          >
                            <input
                              name="email"
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleChange}
                              autoComplete="off"
                              style={AuthFormSignUpInputsForMobile}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      {/* Phone and Company */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        // sx={{ backgroundColor: "red" }}
                      >
                        <Box sx={AuthFormSignUpInputsAlignPhoneAndEmail}>
                          {/* Phone */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                              },
                            }}
                          >
                            <input
                              name="phone"
                              placeholder="Phone"
                              value={formData.phone}
                              onChange={handleChange}
                              autoComplete="off"
                              style={AuthFormSignUpInputsForMobile}
                            />
                          </Box>

                          {/* Company */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                              },
                            }}
                          >
                            <input
                              name="company"
                              placeholder="Company"
                              value={formData.company}
                              onChange={handleChange}
                              autoComplete="off"
                              style={AuthFormSignUpInputsForMobile}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      {/* State and City */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        // sx={{ backgroundColor: "red" }}
                      >
                        <Box sx={AuthFormSignUpInputsAlignStateandCity}>
                          {/* State */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                              },
                            }}
                          >
                            <select
                              id="dropdown"
                              style={RegistrationStateAndCityStyleForMobile}
                              value={selectedState}
                              onChange={handleSelectChange}
                            >
                              <option
                                style={{
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "20px",
                                }}
                              >
                                State
                              </option>
                              {stateData &&
                                stateData.map((item) => (
                                  <option
                                    key={item?.id}
                                    style={{
                                      fontFamily: "Inter, sans-serif",
                                      fontSize: "16px",
                                    }}
                                    value={item?.id}
                                  >
                                    {item?.name.charAt(0).toUpperCase() +
                                      item?.name.slice(1).toLowerCase()}
                                  </option>
                                ))}
                            </select>
                          </Box>

                          {/* City */}
                          <Box
                            sx={{
                              marginTop: {
                                xs: "5px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                              },
                            }}
                          >
                            <select
                              id="dropdown"
                              style={RegistrationStateAndCityStyleForMobile}
                              value={selectedCity}
                              onChange={(event) =>
                                setSelectedCity(event.target.value)
                              }
                            >
                              <option style={{ color: "red" }}>City</option>
                              {cityData &&
                                cityData.map((item) => (
                                  <option
                                    key={item?.id}
                                    style={{ color: "red" }}
                                    value={item?.id}
                                  >
                                    {item?.city}
                                  </option>
                                ))}
                            </select>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {/* Submit */}
                <Box sx={RegistrationSubmitButtonAlign}>
                  {/* <Box
                    sx={RegistrationSubmitbuttonStyle}
                    onClick={handleSubmit}
                  >
                    <Button>
                      <Typography sx={AuthButtonText}>
                        {" "}
                        {isSubmitting ? (
                          <CircularProgress size={24} sx={{ color: "white" }} /> // Show loader when submitting
                        ) : (
                          "SIGN UP"
                        )}
                      </Typography>
                    </Button>
                  </Box> */}
                  <Button
                    onClick={handleSubmit}
                    style={{
                      ...AuthButtonText,
                      width: "90%",
                      backgroundColor: "red",
                      color: "white",
                      marginBottom: "10px",
                    }}
                    disabled={isSubmitting} // Disable button during submission
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} sx={{ color: "white" }} /> // Show loader when submitting
                    ) : (
                      "SIGN UP"
                    )}
                  </Button>
                </Box>

                {/* Already have an account */}
                <Typography sx={AlreadyAndHaveNot} onClick={toggleForm}>
                  Already have an account? Sign In
                </Typography>
              </Grid>

              <Grid
                item
                xs={0}
                sm={1}
                md={1}
                lg={1}
                // sx={{ backgroundColor: "orange" }}
              ></Grid>
            </Grid>
          </>
        ) : (
          <>
            {/* LOGIN FORM */}
            <Grid container sx={AuthModalLoginstyle}>
              <Grid
                item
                xs={0}
                sm={1}
                md={1}
                lg={1}
                // sx={{ backgroundColor: "orange" }}
              ></Grid>

              <Grid
                item
                xs={12}
                sm={10}
                md={10}
                lg={10}
                // sx={{ backgroundColor: "pink" }}
              >
                {/* Title and Subtitles */}
                <Box
                  sx={{
                    marginTop: {
                      xs: "20px",
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                    },
                  }}
                >
                  <Typography sx={FillUpFor}>FILL UP FOR</Typography>

                  <Typography sx={RegistrationAndLoginText}>LOGIN</Typography>
                </Box>

                {/* Images */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },

                    marginTop: {
                      xs: "15px",
                      sm: "15px",
                      md: "10px",
                      lg: "10px",
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={AuthFormImage}
                    sx={AuthFormImageStyle}
                  />
                </Box>

                {/* Inputs */}
                <Box
                  sx={{
                    padding: {
                      xs: "0px 30px 0px 30px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "0px 0px 0px 0px",
                    },
                  }}
                >
                  <Grid container>
                    {/* Email and Password */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      // sx={{ backgroundColor: "red" }}
                    >
                      <Box sx={AuthFormLoginInputsAlign}>
                        {/* Email */}
                        <Box
                          sx={{
                            marginTop: {
                              xs: "5px",
                              sm: "10px",
                              md: "10px",
                              lg: "10px",
                            },
                          }}
                        >
                          <input
                            type="email"
                            placeholder="Email"
                            style={AuthFormSignUpInputs}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    {/* Password */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      // sx={{ backgroundColor: "red" }}
                    >
                      <Box sx={AuthFormLoginInputsAlign}>
                        {/* password */}
                        <Box
                          sx={{
                            marginTop: {
                              xs: "5px",
                              sm: "10px",
                              md: "10px",
                              lg: "10px",
                            },
                          }}
                        >
                          <input
                            type="password"
                            placeholder="Password"
                            style={AuthFormSignUpInputs}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {/* Buttons */}

                {/* Submit */}
                <Box sx={LoginpageSubmitButtonAlign}>
                  <Box sx={LoginpageSubmitButtonStyle}>
                    <Button disableRipple onClick={handleLogin}>
                      <Typography sx={AuthButtonText}>Submit</Typography>
                    </Button>
                  </Box>
                </Box>

                {/* or */}
                <Typography sx={ortextStyle}>or</Typography>

                {/* Login with gmail */}
                <Box sx={loginwithGmailbuttonAlign}>
                  <Box sx={AuthFormButtonStyle} onClick={toggleForm}>
                    <Box
                      component="img"
                      src={GoogleLogIn}
                      sx={GooglelogoStyle}
                    />
                    <Button disableRipple>
                      <Typography sx={LoginwithGmailText}>
                        Login with Gmail
                      </Typography>
                    </Button>
                  </Box>
                </Box>

                <Typography sx={AlreadyAndHaveNot} onClick={toggleForm}>
                  Have no account? Sign Up
                </Typography>
              </Grid>

              <Grid
                item
                xs={0}
                sm={1}
                md={1}
                lg={1}
                // sx={{ backgroundColor: "orange" }}
              ></Grid>
            </Grid>
          </>
        )}
      </Modal>

      {/* Cart modal */}
      <Modal
        open={opencartmodal}
        onClose={handleCartmodalClose}
        style={{ outline: "none"}}
      >
        <Grid container sx={CartModalstyle}>
          <Grid
            item
            xs={0}
            sm={1}
            md={1}
            lg={1}
            // sx={{ backgroundColor: "orange" }}
          ></Grid>

          <Grid
            item
            xs={12}
            sm={10}
            md={10}
            lg={10}
            // sx={{ backgroundColor: "pink" }}
          >
            <Box
              sx={{
                height: "100px",
                width: "100%",
                marginTop: "20px",
                padding:{
                  xs:"0px 10px 0px 10px",
                  sm:"0px 10px 0px 10px",
                  md:"0px 0px 0px 0px",
                  lg:"0px 0px 0px 0px",
                }
              }}
            >
              <Box>
                <Button
                  sx={{
                    height: "30px",
                    width: "100%",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid black",
                    boxShadow: "5",
                    // cursor: "pointer",
                  }}
                  disableRipple
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      fontFamily: "Inter, sans-serif",
                      color: "#000",
                    }}
                  >
                    Sign Up
                  </Typography>
                </Button>
              </Box>

              <Box sx={{ marginTop: "10px" , }}>
                <Button
                  sx={{
                    height: "30px",
                    width: "100%",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid black",
                    boxShadow: "5",
                    // cursor: "pointer",
                  }}
                  disableRipple
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      fontFamily: "Inter, sans-serif",
                      color: "#000",
                    }}
                  >
                    Sign In
                  </Typography>
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: "30px",
                height: "30%",
                // overflow: "hidden",
                // overflowY: "scroll",
                padding:{
                  xs:"0px 10px 0px 10px",
                  sm:"0px 10px 0px 10px",
                  md:"0px 0px 0px 0px",
                  lg:"0px 0px 0px 0px",
                }
              }}
            >
              {/* Product 1 */}
              <Box>
                {showContent && (
                  <>
                    {/* Product 1 */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: "#E8E8E8",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "300px",
                        }}
                      >
                        <Box
                          component="img"
                          src={newImageforCart}
                          sx={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "20px",
                            justifyContent: "space-between",
                            margin: "3px 5px 3px 10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#FF1F2F",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: "600",
                            }}
                          >
                            Unipole nehru place south delhi....
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            Rs 50976/-
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            JO163468
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          component="img"
                          src={SideDrawerCloseIcon}
                          sx={{
                            width: "10px",
                            height: "10px",
                          }}
                          onClick={handleCrossClick}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>

              {/* product 2 */}
              <Box>
                {showContent2 && (
                  <>
                    {/* Product 2 */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: "#E8E8E8",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          // alignItems: "center",

                          // height:"100%",
                          width: "300px",
                        }}
                      >
                        <Box
                          component="img"
                          src={newImageForCart2}
                          sx={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "20px",
                            justifyContent: "space-between",
                            margin: "3px 5px 3px 10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#FF1F2F",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: "600",
                            }}
                          >
                            Unipole jal vihar towards South Delhi
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            Rs 31000/-
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            JO3455868
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          component="img"
                          src={SideDrawerCloseIcon}
                          sx={{
                            width: "10px",
                            height: "10px",
                          }}
                          onClick={handleCrossClick2}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>

              {/* Product3 */}
              <Box>
                {showContent3 && (
                  <>
                    {/* Product 3 */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: "#E8E8E8",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          // alignItems: "center",

                          // height:"100%",
                          width: "300px",
                        }}
                      >
                        <Box
                          component="img"
                          src={newImageForCart2}
                          sx={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "20px",
                            justifyContent: "space-between",
                            margin: "3px 5px 3px 10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#FF1F2F",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: "600",
                            }}
                          >
                            Unipole lajpat nagar market towards...
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            Rs 2600/-
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            JO9588668
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          component="img"
                          src={SideDrawerCloseIcon}
                          sx={{
                            width: "10px",
                            height: "10px",
                          }}
                          onClick={handleCrossClick3}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Box sx={{ height: "100px", width: "100%" }}></Box>
          </Grid>

          <Grid
            item
            xs={0}
            sm={1}
            md={1}
            lg={1}
            // sx={{ backgroundColor: "orange" }}
          ></Grid>
        </Grid>
      </Modal>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Header;
