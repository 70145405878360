import React from "react";

// MUI
import { makeStyles } from "@mui/styles";

import {
  Box,
  Grid,
  Typography,
} from "../../../DesignData/MaterialUi/material-ui";

import { OurProcessData } from "../../../assets/json/newDesign/OurProcessData";

import { Link, useNavigate } from "react-router-dom";
// slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Description,
  TopBannerMainTitle,
  MainMargins,
  TopbannerDescription,
  MainTitle,
  MarginBtwnTypo,
  AboutOutdoorMainTitle,
  AboutOutdoorDescription,
  contentInGrid,
  OutdoorMainBox,
  greyBox,
  icon,
  greyboxTitle,
  greyBoxDesc,
  OurProcessDescription,
  OurProcessMainTitle,
  SectionDescriptionForMobile,
  SectionMainTitleForDesktop,
  SectionMainTitle,
  OutdoorMainTitleMobile,
  OutdoorMainTitleDeskTop,
  OutdoorDescriptionDesktop,
  OutdoorDescriptionMobile,
  AboutOutdoorAdvertisingDesktop,
  AboutOutdoorAdvertisingMobile,
  OutdoorOurProcessMainTitleDeskTop,
  OutdoorOurProcessMainTitleMobile,
  OutdoorOurProcessDescriptionDesktop,
  OutdoorOurDescriptionDescriptionMobile,
  OutdoorOurProcessDescriptionMobile,
} from "../../Styles/Styles";

import {
  outdoorBanner,
  ourServicesbanner,
  weSeeThingsBgImage,
} from "../../../DesignData/Images/Images";

import "./Outdoor.css";
import { SubcategoriesData } from "../../../assets/json/newDesign/SubcategoriesData";
import Blogsection from "../../Home/HomeComponents2/BlogSection/Blogsection";
import Whatoutdoor from "./OutdoorComponents/WhatOutdoor/Whatoutdoor";

const useStyles = makeStyles(() => ({
  bgImageDeskTop: {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${outdoorBanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "400px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bgImageMobile: {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${outdoorBanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "250px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  OurServicsBanner: {
    backgroundImage: `url(${ourServicesbanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  aboutOutdoorAdver: {
    backgroundImage: `url(${weSeeThingsBgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  sideGridBgColor: {
    // backgroundColor: "yellow",
  },
  MainGridBgColor: {
    // backgroundColor: "pink",
  },
}));

const MainBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "25px",
};

const Outdoor = () => {
  // Slider
  var settings = {
    // dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      {/* banner */}
      {/* DeskTop */}
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Grid container className={classes.bgImageDeskTop}>
          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            lg={0.5}
            className={classes.sideGridBgColor}
          ></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={11}
            className={classes.MainGridBgColor}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography sx={TopBannerMainTitle}>
                  Outdoor <span style={{ color: "#FF1F2F" }}>Advertising</span>
                </Typography>
              </Box>
              <Box>
                <Typography sx={TopbannerDescription}>
                  Let's catch up outside!
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            lg={0.5}
            className={classes.sideGridBgColor}
          ></Grid>
        </Grid>
      </Box>
      {/* Mobile */}
      <Box
        sx={{
          display: {
            md: "none",
            lg: "none",
          },
        }}
      >
        <Grid container className={classes.bgImageMobile}>
          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            lg={0.5}
            className={classes.sideGridBgColor}
          ></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={11}
            className={classes.MainGridBgColor}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography sx={TopBannerMainTitle}>
                  Outdoor <span style={{ color: "#FF1F2F" }}>Advertising</span>
                </Typography>
              </Box>
              <Box>
                <Typography sx={TopbannerDescription}>
                  Let's catch up outside!
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            lg={0.5}
            className={classes.sideGridBgColor}
          ></Grid>
        </Grid>
      </Box>

      {/* OurSevices */}
      <Grid container className={classes.OurServicsBanner}>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          className={classes.sideGridBgColor}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          lg={11}
          className={classes.MainGridBgColor}
        >
          <Box sx={MainMargins}>
            {/* title and description */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {/* TITLE */}
              <Box>
                {/* Desktop */}
                <Typography sx={OutdoorMainTitleDeskTop}>
                  OUTDOOR <span style={{ color: "#FF1F2F" }}>SERVICES</span>
                </Typography>
                {/* Mobile */}
                <Typography sx={OutdoorMainTitleMobile}>
                  OUTDOOR <span style={{ color: "#FF1F2F" }}>SERVICES</span>
                </Typography>
              </Box>

              {/* DESCRIPTION */}
              <Box>
                <Box>
                  {/* Desktop */}
                  <Typography sx={OutdoorDescriptionDesktop}>
                    Find your city listings across our extensive range of
                    outdoor advertising services, highlighting <br />
                    brands and their intelligently positioned advertisements.
                  </Typography>
                  {/* mobile */}
                  <Typography sx={OutdoorDescriptionMobile}>
                    Find your city listings across our extensive range of
                    outdoor advertising services, highlighting brands and their
                    intelligently positioned advertisements.
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* categories */}
            <Grid
              container
              sx={{
                // border: "1px solid white",
                marginTop: { xs: "15px", sm: "15px", md: "60px", lg: "60px" },
              }}
            >
              {SubcategoriesData.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  key={index}
                  sx={{
                    display: "flex",
                    // backgroundColor:
                    //   index === 0
                    //     ? "red"
                    //     : index === 1
                    //     ? "blue"
                    //     : index === 2
                    //     ? "orange"
                    //     : index === 3
                    //     ? "magenta"
                    //     : index === 4
                    //     ? "grey"
                    //     : "yellow",
                    justifyContent:
                      index === 0
                        ? {
                            xs: "center",
                            sm: "center",
                            md: "flex-start",
                            lg: "flex-start",
                          }
                        : index === 1
                        ? "center"
                        : index === 2
                        ? "flex-end"
                        : index === 3
                        ? "flex-start"
                        : index === 4
                        ? "center"
                        : "flex-end",
                    padding:
                      index === 0
                        ? {
                            xs: "0px 10px 0px 10px",
                            sm: "0px 10px 0px 10px",
                            md: "0px 30px 30px 0px",
                            lg: "0px 30px 30px 0px",
                          }
                        : index === 1
                        ? {
                            xs: "10px 10px 0px 10px",
                            sm: "0px 10px 0px 10px",
                            md: "0px 15px 30px 15px",
                            lg: "0px 15px 30px 15px",
                          }
                        : index === 2
                        ? {
                            xs: "10px 10px 0px 10px",
                            sm: "0px 10px 0px 10px",
                            md: "0px 0px 30px 30px",
                            lg: "0px 0px 30px 30px",
                          }
                        : index === 3
                        ? {
                            xs: "10px 10px 0px 10px",
                            sm: "0px 10px 0px 10px",
                            md: "15px 30px 0px 0px",
                            lg: "15px 30px 0px 0px",
                          }
                        : index === 4
                        ? {
                            xs: "10px 10px 0px 10px",
                            sm: "0px 10px 0px 10px",
                            md: "15px 15px 0px 15px",
                            lg: "15px 15px 0px 15px",
                          }
                        : {
                            xs: "10px 10px 0px 10px",
                            sm: "0px 10px 0px 10px",
                            md: "15px 0px 0px 30px",
                            lg: "15px 0px 0px 30px",
                          },
                  }}
                >
                  <Link
                    to={item.link}
                    style={{ textDecoration: "none", width: "100%" }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        border: "2px solid white",
                        height: "140px",
                        width: "100%",
                        borderRadius: "15px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                        padding: "20px",
                        "&::before": {
                          content: `"${item.no}"`,
                          position: "absolute",
                          top: "-10px",
                          left: "10%",
                          transform: "translateX(-50%)",
                          backgroundColor: "rgba(0, 0, 0, 0.9)",
                          padding: "0 5px",
                          fontSize: "14px",
                          color: "#fff",
                        },
                      }}
                    >
                      <Link
                        to={item.link}
                        style={{ textDecoration: "none", width: "100%" }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            color: "white",
                            fontSize: "23px",
                            fontWeight: "400",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "center",
                            color: "white",
                            fontSize: "23px",
                            fontWeight: "400",
                          }}
                        >
                          {item.subTitle}
                        </Typography>
                      </Link>
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          className={classes.sideGridBgColor}
        ></Grid>
      </Grid>

      {/* About Outdoor Advertising */}
      <Grid container className={classes.aboutOutdoorAdver}>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          className={classes.sideGridBgColor}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          lg={11}
          className={classes.MainGridBgColor}
        >
          <Box sx={MainMargins}>
            {/* Title */}
            <Box>
              {/* Desktop */}
              <Typography sx={OutdoorMainTitleDeskTop}>
                <span style={{ color: "black" }}>ABOUT </span>
                <span style={{ color: "#FF1F2F" }}>OUTDOOR ADVERTISING</span>
              </Typography>

              {/* Mobile */}
              <Typography sx={OutdoorMainTitleMobile}>
                <span style={{ color: "black" }}>ABOUT </span>
                <span style={{ color: "#FF1F2F" }}>OUTDOOR ADVERTISING</span>
              </Typography>
            </Box>

            {/* Description */}
            <Box sx={MarginBtwnTypo}>
              {/* Desktop */}
              <Typography sx={AboutOutdoorAdvertisingDesktop}>
                Outdoor advertising is also known as out-of-the-house
                advertising, which encompasses diversified media forms found
                outside the home. This kind of advertising strategically places
                ads, signage, and digital displays in high-traffic areas like
                public transport, terminals, etc. With millions of travellers
                daily, it offers a unique chance to reach a captive and diverse
                audience.
              </Typography>
              {/* Mobile */}
              <Typography sx={AboutOutdoorAdvertisingMobile}>
                Outdoor advertising is also known as out-of-the-house
                advertising, which encompasses diversified media forms found
                outside the home. This kind of advertising strategically places
                ads, signage, and digital displays in high-traffic areas like
                public transport, terminals, etc. With millions of travellers
                daily, it offers a unique chance to reach a captive and diverse
                audience.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          className={classes.sideGridBgColor}
        ></Grid>
      </Grid>

      {/* OurProcess */}
      <Grid container className={classes.bgImage}>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          className={classes.sideGridBgColor}
        ></Grid>

        {/*Desktop Content */}
        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          lg={11}
          className={classes.MainGridBgColor}
          display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
        >
          <Box sx={MainMargins}>
            {/* Title */}
            {/* DeskTop */}
            <Typography sx={OutdoorOurProcessMainTitleDeskTop}>
              OUR PROCESS
            </Typography>
            {/* Description */}
            <Box>
              <Typography sx={OutdoorOurProcessDescriptionDesktop}>
                Connect with us to secure the perfect spot for your brand, and
                let's collaborate
                <br />
                to create an impactful deal that elevates your outdoor presence.
              </Typography>
            </Box>
            <Box></Box>

            {/* cards */}
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              {OurProcessData.map((item, index) => (
                <Box
                  sx={{
                    margin: { xs: "10px", sm: "10px", md: "0px", lg: "0px" },
                  }}
                >
                  {/* MainBox */}
                  <Box sx={MainBox}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      {/* White */}
                      <Box
                        sx={{
                          backgroundColor: "white",
                          width: "125px",
                          height: "300px",
                          borderTop:
                            index === 0
                              ? "5px dashed #FF0000"
                              : index === 1
                              ? "5px dashed #FF4646"
                              : index === 2
                              ? "5px dashed #FF6565"
                              : "5px dashed #FF8A8A",
                          borderLeft:
                            index === 0
                              ? "5px dashed #FF0000"
                              : index === 1
                              ? "5px dashed #FF4646"
                              : index === 2
                              ? "5px dashed #FF6565"
                              : "5px dashed #FF8A8A",
                          borderBottom:
                            index === 0
                              ? "5px dashed #FF0000"
                              : index === 1
                              ? "5px dashed #FF4646"
                              : index === 2
                              ? "5px dashed #FF6565"
                              : "5px dashed #FF8A8A",
                          borderRadius: "30px 0px 0px 30px",
                        }}
                      ></Box>
                      {/* Red */}
                      <Box sx={{ position: "relative" }}>
                        <Box
                          sx={{
                            backgroundColor:
                              index === 0
                                ? "#FF0000"
                                : index === 1
                                ? "#FF4646"
                                : index === 2
                                ? "#FF6565"
                                : "#FF8A8A",
                            width: "125px",
                            height: "300px",
                            borderRadius: "0px 30px 30px 0px",
                          }}
                        ></Box>
                        <Box
                          component="img"
                          src={item.poylgonImg}
                          sx={{
                            width: "100px",
                            height: "200px",
                            position: "absolute",
                            bottom: "50px",
                            left: "50px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box sx={greyBox}>
                      <Box component="img" src={item.image} sx={icon} />
                      <Typography sx={greyboxTitle}>{item.title}</Typography>
                      <Typography sx={greyBoxDesc}>
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        {/*Mobile Content */}
        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          lg={11}
          className={classes.MainGridBgColor}
          display={{ md: "none", lg: "none" }}
        >
          <Box sx={MainMargins}>
            {/* Title */}
            <Typography sx={OutdoorOurProcessMainTitleMobile}>
              OUR PROCESS
            </Typography>
            {/* Description */}

            {/* mobile */}
            <Typography sx={OutdoorOurProcessDescriptionMobile}>
              Connect with us to secure the perfect spot for your brand, and
              let's collaborate to create an impactful deal that elevates
              <br /> your outdoor presence.
            </Typography>

            {/* cards */}
            <Box sx={{ marginTop: "10px" }}>
              <Slider
                {...settings}
                // style={{ backgroundColor: "red" }}
              >
                {OurProcessData.map((item, index) => (
                  <Box
                    sx={{
                      margin: { xs: "10px", sm: "10px", md: "0px", lg: "0px" },
                    }}
                  >
                    {/* MainBox */}
                    <Box sx={MainBox}>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        {/* White */}
                        <Box
                          sx={{
                            backgroundColor: "white",
                            width: "125px",
                            height: "300px",
                            borderTop:
                              index === 0
                                ? "5px dashed #FF0000"
                                : index === 1
                                ? "5px dashed #FF4646"
                                : index === 2
                                ? "5px dashed #FF6565"
                                : "5px dashed #FF8A8A",
                            borderLeft:
                              index === 0
                                ? "5px dashed #FF0000"
                                : index === 1
                                ? "5px dashed #FF4646"
                                : index === 2
                                ? "5px dashed #FF6565"
                                : "5px dashed #FF8A8A",
                            borderBottom:
                              index === 0
                                ? "5px dashed #FF0000"
                                : index === 1
                                ? "5px dashed #FF4646"
                                : index === 2
                                ? "5px dashed #FF6565"
                                : "5px dashed #FF8A8A",
                            borderRadius: "30px 0px 0px 30px",
                          }}
                        ></Box>
                        {/* Red */}
                        <Box sx={{ position: "relative" }}>
                          <Box
                            sx={{
                              backgroundColor:
                                index === 0
                                  ? "#FF0000"
                                  : index === 1
                                  ? "#FF4646"
                                  : index === 2
                                  ? "#FF6565"
                                  : "#FF8A8A",
                              width: "125px",
                              height: "300px",
                              borderRadius: "0px 30px 30px 0px",
                            }}
                          ></Box>
                          <Box
                            component="img"
                            src={item.poylgonImg}
                            sx={{
                              width: "100px",
                              height: "200px",
                              position: "absolute",
                              bottom: "50px",
                              left: "50px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={greyBox}>
                        <Box component="img" src={item.image} sx={icon} />
                        <Typography sx={greyboxTitle}>{item.title}</Typography>
                        <Typography sx={greyBoxDesc}>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          className={classes.sideGridBgColor}
        ></Grid>
      </Grid>

      {/* Blog */}
      <Blogsection />

      {/* WHAT OUTDOOR ADVERTISING INCLUDES? */}
      <Whatoutdoor />
    </>
  );
};

export default Outdoor;
