import React from "react";

// MUI
import { Grid, Typography, Button, Box } from "@mui/material";

// FONT CSS
import "./ExperienceCom.css";

import CountUp from "react-countup";

const ExperienceCom = () => {
  return (
    <>
      {/* TITLE */}
      <Grid container sx={{ backgroundColor: "black" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          // sx={{ backgroundColor: "green" }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          lg={11}
          // display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
          // sx={{ backgroundColor: "red" }}
        >
          {/* title and description */}
          <Box
            sx={{
              marginTop: {
                xs: "20px",
                sm: "20px",
                md: "60px",
                lg: "60px",
              },
              marginBottom: {
                xs: "20px",
                sm: "20px",
                md: "60px",
                lg: "60px",
              },
            }}
          >
            {/* Title */}
            <Box>
              <Typography
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "600",
                  fontSize: "33px",
                  lineHeight: "33px",
                  letterSpacing: "8%",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Experience
              </Typography>
            </Box>
            {/* description */}
            <Box
              sx={{
                marginTop: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "16px",
                    lg: "16px",
                  },
                  fontWeight: "500",
                  fontFamily: "Inter, sans-serif",
                  textAlign: "center",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Crunching some numbers - an overview of our deliverables
              </Typography>
            </Box>

            {/* circles */}

            <Box
              sx={{
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                paddingLeft: {
                  xs: "20px",
                  sm: "20px",
                  md: "100px",
                  lg: "100px",
                },
                paddingRight: {
                  xs: "20px",
                  sm: "20px",
                  md: "100px",
                  lg: "100px",
                },
                marginTop: {
                  xs:"20px",
                  sm:"20px",
                  md:"50px",
                  lg:"50px"
                },
              }}
            >
              {/* Green */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80px",
                      sm: "80px",
                      md: "200px",
                      lg: "200px",
                    },
                    height: {
                      xs: "80px",
                      sm: "80px",
                      md: "200px",
                      lg: "200px",
                    },
                    borderRadius: "50%",
                    backgroundColor: "rgba(85, 255, 0, 0.7)",
                    animation: "animate1 5s linear forwards infinite",
                    transform: "translate(0%, 0%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#000",
                      height: "90%",
                      width: "90%",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        // marginTop: "15px",
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "35px",
                          lg: "35px",
                        },
                        fontWeight: "700",
                        fontFamily: "Inter, sans-serif",
                        color: "#FF1F2F",
                      }}
                    >
                      <CountUp start={0} end={100} />
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        // marginTop: "15px",
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "35px",
                          lg: "35px",
                        },
                        fontWeight: "700",
                        fontFamily: "Inter, sans-serif",
                        color: "#FF1F2F",
                      }}
                    >
                      k+
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: {
                        xs: "500px",
                        sm: "500",
                        md: "600",
                        lg: "600",
                      },
                      fontSize: {
                        xs: "10px",
                        sm: "10px",
                        md: "20px",
                        lg: "20px",
                      },
                      fontFamily: "Inter, sans-serif",
                      marginTop: {
                        xs: "10px",
                        sm: "10px",
                        md: "15px",
                        lg: "15px",
                      },
                    }}
                  >
                    HAPPY CLIENTS
                  </Typography>
                </Box>
              </Box>
              {/* Blue */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      width: {
                        xs: "80px",
                        sm: "80px",
                        md: "200px",
                        lg: "200px",
                      },
                      height: {
                        xs: "80px",
                        sm: "80px",
                        md: "200px",
                        lg: "200px",
                      },
                      borderRadius: "50%",
                      backgroundColor: "rgb(106, 90, 205)",
                      animation: "animate2 5s linear forwards infinite",
                      transform: "translate(0%, 0%)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#000",
                        height: "90%",
                        width: "90%",
                        borderRadius: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          // marginTop: "15px",
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "35px",
                            lg: "35px",
                          },
                          fontWeight: "700",
                          fontFamily: "Inter, sans-serif",
                          color: "#FF1F2F",
                        }}
                      >
                        <CountUp start={0} end={423} />
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          // marginTop: "15px",
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "35px",
                            lg: "35px",
                          },
                          fontWeight: "700",
                          fontFamily: "Inter, sans-serif",
                          color: "#FF1F2F",
                        }}
                      >
                        k+
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: {
                        xs: "500px",
                        sm: "500",
                        md: "600",
                        lg: "600",
                      },
                      fontSize: {
                        xs: "10px",
                        sm: "10px",
                        md: "20px",
                        lg: "20px",
                      },
                      fontFamily: "Inter, sans-serif",
                      marginTop: {
                        xs: "10px",
                        sm: "10px",
                        md: "15px",
                        lg: "15px",
                      },
                    }}
                  >
                    OOH SITES
                  </Typography>
                </Box>
              </Box>
              {/* yellow */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      width: {
                        xs: "80px",
                        sm: "80px",
                        md: "200px",
                        lg: "200px",
                      },
                      height: {
                        xs: "80px",
                        sm: "80px",
                        md: "200px",
                        lg: "200px",
                      },
                      borderRadius: "50%",
                      backgroundColor: "rgb(255, 165, 0)",
                      animation: "animate3 5s linear forwards infinite",
                      transform: "translate(0%, 0%)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#000",
                        height: "90%",
                        width: "90%",
                        borderRadius: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          // marginTop: "15px",
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "35px",
                            lg: "35px",
                          },
                          fontWeight: "700",
                          fontFamily: "Inter, sans-serif",
                          color: "#FF1F2F",
                        }}
                      >
                        <CountUp start={0} end={47} />
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          // marginTop: "15px",
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "35px",
                            lg: "35px",
                          },
                          fontWeight: "700",
                          fontFamily: "Inter, sans-serif",
                          color: "#FF1F2F",
                        }}
                      >
                        CR
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: {
                          xs: "500px",
                          sm: "500",
                          md: "600",
                          lg: "600",
                        },
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "20px",
                          lg: "20px",
                        },
                        fontFamily: "Inter, sans-serif",
                        marginTop: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                        },
                      }}
                    >
                      PEOPLE IMPACTED
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          lg={0.5}
          // sx={{ backgroundColor: "green" }}
        ></Grid>
      </Grid>

    </>
  );
};

export default ExperienceCom;
