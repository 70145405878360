import {
  HomeIcon,
  AboutUsIcon,
  OurServiceIcon,
  ContactUsIcon,
  BlogIcon,
} from "../../../DesignData/Images/Images.js";

export const SideDrawerContent = [
  {
    id: "1",
    image: HomeIcon,
    title: "Home",
    link:"/"
  },
  {
    id: "2",
    image: AboutUsIcon,
    title: "About Us",
    link:"/about-us/"

  },
  {
    id: "3",
    image: OurServiceIcon,
    title: "Our Services",
    link:"/our-service/"

  },
  {
    id: "4",
    image: BlogIcon,
    title: "Blogs",
    link:"/blogs/"

  },
  {
    id: "5",
    image: ContactUsIcon,
    title: "Contact Us",
    link:"/contact-us/"

  },
];
